<template>
  <div id="locale-switch">
    <Sidebar
      class="locale-sidebar sf-sidebar--right"
      :visible="isLocaleSidebarOpen"
      :title="$t('Choose your language')"
      @close="toggleLocaleSidebar"
    >
      <template #circle-icon="{ close }">
        <button class="sf-button sf-button--square close-button" @click="close">
          <TimesIcon />
        </button>
      </template>

      <SfList v-if="localeSwitchItems" class="store-list">
        <SfListItem v-for="store in localeSwitchItems" :key="store.id" class="store-list__item">
          <a
            class="sf-link store-list__item-link"
            :class="locale === store.iso ? 'store-list__item-link--selected' : ''"
            :href="formatUrl(store.url)"
            :data-source="store['data-source']"
          >
            <SfCharacteristic class="language" :class="locale === store.iso ? 'language--selected' : ''">
              <template #text>
                <div class="language__text">
                  <span>{{ store.name }}</span>
                  <CheckIcon v-if="locale === store.iso" class="language__icon--current" aria-hidden="true" />
                </div>
              </template>
              <template #icon>
                <component
                  :is="flagIcon(store.iso?.toLowerCase())"
                  class="language__flag"
                  :class="'language__flag--' + store.iso?.toLowerCase()"
                  width="21"
                  height="16"
                />
              </template>
            </SfCharacteristic>
          </a>
        </SfListItem>
      </SfList>
    </Sidebar>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'

import deFlagIcon from '~/assets/icons/flags/de.svg'
import enFlagIcon from '~/assets/icons/flags/en.svg'
import frFlagIcon from '~/assets/icons/flags/fr.svg'
import nlFlagIcon from '~/assets/icons/flags/nl.svg'

import CheckIcon from '~/assets/icons/checkmark.svg'
import TimesIcon from '~/assets/icons/times.svg'

const uiState = useUiState()
const { toggleLocaleSidebar } = uiState
const { isLocaleSidebarOpen } = storeToRefs(uiState)
const { hrefLangs } = storeToRefs(usePageStore())
const { locale } = useI18n()

const localeSwitchItems = computed(() => hrefLangs.value?.filter((hrefLang) => hrefLang.language !== 'x-default'))

const flagIcon = (locale) => {
  const localeString = locale?.substring(0, 2)
  switch (localeString) {
    case 'de':
      return deFlagIcon
    case 'fr':
      return frFlagIcon
    case 'nl':
      return nlFlagIcon
    default:
      return enFlagIcon
  }
}
</script>

<style lang="scss">
#locale-switch {
  --sidebar-z-index: 30;
  --overlay-z-index: 30;
}

.locale-sidebar {
  .language {
    padding: var(--spacer-xs);
    min-height: var(--spacer-xl);

    &--selected {
      background-color: var(--gray-background-color);
    }

    &__flag {
      display: block;
      margin-right: var(--spacer-sm);
      overflow: hidden;

      &--en {
        background: #039;
      }
    }

    &__icon--current {
      margin-left: auto;
    }

    &__text {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .store-list {
    .store-list__item {
      --list-item-margin: var(--spacer-xs);
      --list-item-padding: 0;
      --list-item-font-family: var(--font-family--primary);

      display: block;
      border-radius: var(--spacer-2xs);
      overflow: hidden;

      &:hover {
        background-color: var(--gray-background-color);
      }
    }

    .store-list__item-link {
      --link-text-decoration: none;
      --link-font-family: var(--font-family--primary);
      --link-font-size: var(--font-size--sm);
      --link-font-weight: var(--font-weight--normal);
      --link-font-line-height: 1.4286;
      --link-color: var(--black-color);

      @include for-desktop {
        --link-font-size: var(--font-size--base);
        --link-font-line-height: var(--global-line-height);
      }
    }
  }

  .sf-bottom-modal {
    z-index: 2;
    left: 0;
    @include for-desktop {
      --bottom-modal-height: 100vh;
    }
  }

  .sf-bottom-modal:deep(.sf-bottom-modal__close) {
    position: var(--circle-icon-position, absolute);
    top: var(--spacer-xs);
    right: var(--spacer-xs);
  }

  .close-button {
    position: absolute;
    right: 100%;
    top: 0;
  }
}
</style>
