import { defineStore } from 'pinia'

export interface SizeChartItem {
  displayName: string
  categoryId: string
  documentRef: string
}

interface State {
  loading: boolean
  sizeChartItems: SizeChartItem[]
  sizeChartContent: Record<string, string>
  currentSizeChartRef: string
}

export const useSizeChartStore = defineStore('sizeChart', () => {
  const logger = useAppLogger("useSizeChartStore")
  const state: State = reactive({
    loading: false,
    sizeChartItems: [],
    sizeChartContent: {},
    currentSizeChartRef: '',
  })

  const { $brxmEndpointResolver } = useNuxtApp()

  /**
   * Loads a list of size chart items. We'll use the document refs in the items to get the size chart content when it matches a categoryId
   * @returns {Promise<void>} A promise that resolves when the size chart items are loaded.
   */
  const loadSizeChartItems = async () => {
    if (state.sizeChartItems.length > 0) return

    state.loading = true

    try {
      const data = await $fetch($brxmEndpointResolver.resolveDocumentsEndPoint('/content/size-guide/sizechartlist'))
      const sizeChartItemRefs =
        data?.content[data?.document?.$ref.substring(9)].data?.sizeChartItems?.map((item) =>
          item.$ref.split('/').pop(),
        ) || []

      const items = sizeChartItemRefs.map((ref) => {
        const item = data?.content?.[ref]?.data

        return <SizeChartItem>{
          displayName: item.displayName,
          categoryId: item.categoryId,
          documentRef: item.sizeChartRichText?.uuid,
        }
      })

      state.sizeChartItems = items
    } catch (error) {
      logger.error('sizeChartStore/loadSizeChartItems', error)
    }

    state.loading = false
  }

  /**
   * Loads the size chart content for a given document reference.
   * @param {string} documentRef - The reference of the document containing the size chart.
   * @returns {Promise<void>} - A promise that resolves when the size chart content is loaded.
   */
  const loadSizeChart = async (documentRef) => {
    if (state.sizeChartContent[documentRef]) return (state.currentSizeChartRef = documentRef)
    state.loading = true

    try {
      const data = await $fetch(`${$brxmEndpointResolver.resolveDocumentsEndPoint(documentRef)}`)
      state.sizeChartContent[documentRef] = data?.content?.[data?.document?.$ref.substring(9)]?.data?.richText?.value
    } catch (error) {
      logger.error('sizeChartStore/loadSizeChart', error)
    }

    state.loading = false
    state.currentSizeChartRef = documentRef
  }

  const currentSizeChart = computed(() => state.sizeChartContent[state.currentSizeChartRef])

  return {
    ...toRefs(state),
    currentSizeChart,
    loadSizeChartItems,
    loadSizeChart,
  }
})

export default useSizeChartStore
