<template>
  <div class="gift-wrapping-card">
    <nuxt-link
      class="sf-button--pure gift-wrapping-card__image"
      :to="link"
      aria-label="Go To Product"
      data-testid="product-link"
    >
      <nuxt-img
        class="sf-product-card__image"
        :src="product.media_url.url"
        :alt="product.name"
        :width="200"
        :height="300"
      />
    </nuxt-link>

    <nuxt-link class="gift-wrapping-card__info" :to="link">{{ product.name }}</nuxt-link>

    <div class="gift-wrapping-card__actions">
      <div class="price">
        {{ getNumericPriceFromString(product.special_price ? product.special_price : product.price) }}
      </div>
      <SfButton
        class="add-button"
        :aria-label="`Add to Cart ${product.name}`"
        :disabled="productAddingToCart"
        data-testid="product-add-icon"
        @click="addGiftItemToCart(product)"
      >
        <span class="add-button__icons">
          <SfLoader
            v-if="productAddingToCart"
            class="add-button__loader"
            name="sf-pulse"
            mode="out-in"
            :loading="true"
          />
          <BasketIcon v-else />
        </span>
      </SfButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCart } from '~/composables'
import getNumericPriceFromString from '@/utils/stringHelpers'
import BasketIcon from '~/assets/icons/shopping-bag.svg'

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
})

const { t } = useI18n()
const localePath = useLocalePath()
const { addItem: addItemToCart } = useCart()
const productAddingToCart = ref(false)

const link = localePath(`/${props.product.product_url.split('/').pop()}`)

const notificationMessage = useState('notificationMessage')

const showAddedGiftwrapNotification = (product) => {
  notificationMessage.value = t('You added {product} to your shopping cart.', {
    product: product?.name,
  }) as string

  setTimeout(() => (notificationMessage.value = null), 3000)
}

const addGiftItemToCart = async (product: any) => {
  productAddingToCart.value = true
  await addItemToCart({
    // @ts-ignore
    product: { sku: product.sku, __typename: 'SimpleProduct' },
    quantity: 1,
  }).catch(() => {})
  productAddingToCart.value = false
  showAddedGiftwrapNotification(product)
}
</script>

<style lang="scss">
.gift-wrapping-card {
  display: flex;
  flex-direction: column;
  gap: var(--spacer-2xs);
  background: var(--white-color);

  &__image {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 150%;
    overflow: hidden;
    border-radius: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__info {
    position: relative;
    overflow: hidden;

    padding: 0 var(--spacer-xs);
    height: var(--spacer-15);
    font-size: 10px;
    text-overflow: ellipsis;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 var(--spacer-xs) var(--spacer-xs);

    .price {
      font-size: var(--font-size-sm);
      font-weight: var(--font-weight-bold);
    }

    .sf-button {
      padding: 0;
      height: var(--spacer-lg);
      width: var(--spacer-2xl);
    }

    @include for-desktop {
      flex-wrap: nowrap;
      .sf-button {
        width: var(--spacer-xl);
      }
    }
  }

  .add-button {
    &__loader {
      --loader-overlay-background: transparent;
      --loader-spinner-stroke: var(--black-color);
      width: var(--spacer-md);
      height: var(--spacer-md);
      min-height: var(--spacer-md);
    }
  }
}
</style>
