<template>
  <section class="sf-modal" :class="[staticClass, className]">
    <SfOverlay v-if="overlay" class="sf-modal__overlay" :transition="transitionOverlay" :visible="visible"> </SfOverlay>
    <transition :name="transitionModal">
      <div v-if="visible" v-focus-trap v-click-outside="checkPersistence" class="sf-modal__container">
        <SfButton
          class="sf-button--pure sf-modal__close"
          :class="{ 'display-none': !cross }"
          aria-label="Close modal"
          type="button"
          data-testid="close-button"
          @click="close"
        >
          <slot name="close">
            <SfIcon icon="cross" size="0.875rem" color="color-black" />
          </slot>
        </SfButton>
        <div ref="content" class="sf-modal__content">
          <slot />
        </div>
      </div>
    </transition>
  </section>
</template>
<script>
import { focusTrap } from './utilities/directives'
import { clickOutside } from './utilities/directives'

export default defineComponent({
  name: 'SfModal',
  directives: { focusTrap, clickOutside },
  model: {
    prop: 'visible',
    event: 'close',
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    cross: {
      type: Boolean,
      default: true,
    },
    overlay: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    transitionOverlay: {
      type: String,
      default: 'sf-fade',
    },
    transitionModal: {
      type: String,
      default: 'sf-fade',
    },
  },
  data() {
    return {
      staticClass: null,
      className: null,
    }
  },
  methods: {
    close() {
      this.$emit('close', false)
    },
    checkPersistence() {
      if (!this.persistent) {
        this.close()
      }
    },
  },
})
</script>
<style lang="scss">
@import './styles/molecules/SfModal.scss';

.sf-modal {
  &__bar {
    display: none;
  }

  &__content {
    padding: var(--spacer-base);
  }

  &__close.sf-button {
    --button-height: var(--spacer-base);
    --button-width: var(--spacer-base);
    display: flex;
    justify-content: flex-end;
  }
}
</style>
