import { getName, getSlug as getSlugGetter, getProductThumbnailImage } from './magentoProductGetters'

export const getItems = (cart) => {
  if (!cart || !cart.items) {
    return []
  }

  return cart.items
}

export const getItemName = (product) => getName(product.product)
export const getSlug = (product) => getSlugGetter(product.product)

export const getItemImage = (product) => getProductThumbnailImage(product.product)

export const getItemPrice = (product) => {
  if (!product || !product.prices) {
    return {
      regular: 0,
      special: 0,
    }
  }
  if (product.prices) {
    return {
      regular: product.prices.row_total.value || 0,
      special: product.prices.total_item_discount.value || 0,
    }
  }
  const regularPrice = product.product?.price_range?.minimum_price?.regular_price?.value
  const specialPrice = product.product?.price_range?.minimum_price?.final_price?.value

  return {
    regular: regularPrice || 0,
    special: specialPrice || 0,
    // @ts-ignore
    credit: Math.round(specialPrice / 10),
    discountPercentage: 100 - Math.round((specialPrice / regularPrice) * 100),
    total: product.prices?.row_total?.value,
  }
}

export const productHasSpecialPrice = (product) => getItemPrice(product).regular < getItemPrice(product).special

export const getItemQty = (product) => product.quantity

export const getItemAttributes = (product, _filterByAttributeName) => {
  const attributes = {}

  if (!product || !product.configurable_options) {
    return attributes
  }

  const configurableOptions = product.configurable_options

  // eslint-disable-next-line no-restricted-syntax
  for (const option of configurableOptions) {
    attributes[option.attribute_code] = {
      name: option.attribute_code,
      label: option.label,
      value: option.values.map((value) => {
        const obj = {}
        obj[value.value_index] = value.label
        return obj
      }),
    }
  }
  return attributes
}

export const getItemSku = (product) => product?.product?.sku || ''

const calculateDiscounts = (discounts) =>
  discounts.reduce((a, b) => Number.parseFloat(`${a}`) + Number.parseFloat(`${b.amount.value}`), 0)

export const getTotals = (cart) => {
  if (!cart || !cart.prices) return {}

  const subtotal = cart.prices?.subtotal_including_tax?.value
  return {
    total: cart.prices.grand_total?.value,
    subtotal: cart.prices.subtotal_including_tax?.value,
    special: cart.prices.discounts ? subtotal - calculateDiscounts(cart.prices.discounts) : subtotal,
  }
}

export const getShippingPrice = (cart) => {
  if (!cart.shipping_addresses) {
    return 0
  }

  return cart.shipping_addresses.reduce((acc, shippingAddress) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { selected_shipping_method } = shippingAddress

    if (selected_shipping_method) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      return acc + selected_shipping_method.amount.value
    }

    return acc
  }, 0)
}

export const getTotalItems = (cart) => {
  if (!cart) {
    return 0
  }
  return cart.total_quantity
}

export const getConfiguredVariant = (product) => product?.configured_variant || null

export const getCoupons = (cart) =>
  Array.isArray(cart?.applied_coupons)
    ? cart.applied_coupons.map((c) => ({
        id: c.code,
        name: c.code,
        value: 0,
        code: c.code,
      }))
    : []

export const getDiscounts = (cart) =>
  Array.isArray(cart?.prices?.discounts)
    ? cart.prices.discounts.map((d) => ({
        id: d.label,
        name: d.label,
        description: '',
        value: d.amount.value,
        code: d.label,
      }))
    : []

export const getDiscountAmount = (cart) => calculateDiscounts(cart?.prices?.discounts ?? [])

export const getAppliedCoupon = (cart) =>
  Array.isArray(cart?.applied_coupons) && cart?.applied_coupons.length > 0
    ? {
        id: cart.applied_coupons[0].code,
        name: cart.applied_coupons[0].code,
        value: 0,
        code: cart.applied_coupons[0].code,
      }
    : null

export const getSelectedShippingMethod = (cart) =>
  cart?.shipping_addresses?.length > 0 ? cart?.shipping_addresses[0]?.selected_shipping_method : null

export const getAvailablePaymentMethods = (cart) =>
  cart?.available_payment_methods.map((p) => ({
    label: p.title,
    value: p.code,
  }))

export const getStockStatus = (product) => product.product.stock_status

const cartGetters = {
  getAppliedCoupon,
  getAvailablePaymentMethods,
  getCoupons,
  getDiscounts,
  getItemAttributes,
  getItemImage,
  getItemName,
  getSlug,
  getItemPrice,
  getItemQty,
  getItems,
  getItemSku,
  getSelectedShippingMethod,
  getShippingPrice,
  getTotalItems,
  getTotals,
  getDiscountAmount,
  productHasSpecialPrice,
  getStockStatus,
  getConfiguredVariant,
}

export default cartGetters
